<template>
  <div>
    <head-layout :head-title="$t('cip.dc.dataDictionary.title.name2')"
                  :head-btn-options="headBtnOptions"
                  @head-save="saveFn('save')"
                  @head-save-back="saveFn('back')"
                  @head-cancel="headCancel"
    ></head-layout>
    <div style="background-color: #fff;margin: 10px;">
      <el-form  :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataDictionary.field.FieldCode')" prop="dict_code">
              <el-input :placeholder="$t('cip.dc.dataDictionary.field.Datasourcecode')"  v-model="form.dict_code"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataDictionary.field.DictionaryName')" prop="dict_name">
              <el-input  :placeholder="$t('cip.dc.dataDictionary.field.Datasourcename')"  v-model="form.dict_name"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.dataDictionary.field.DictionaryType')" prop="dict_type">
              <el-select  @change="changeFn" v-model="form.dict_type" :placeholder="$t('cip.dc.dataDictionary.field.Databasename')">
                <el-option value="list" :label="$t('cip.dc.dataDictionary.field.list')"></el-option>
                <el-option value="tree" :label="$t('cip.dc.dataDictionary.field.tree')"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-row style="margin-bottom: 20px">
          <el-col >
            <i @click="tabelAdd()" class="el-icon-circle-plus-outline" style="font-size:30px;margin-right: 20px;cursor: pointer"></i>
            <i  @click="delTableData" class="el-icon-remove-outline" style="font-size:30px;cursor: pointer"></i>
          </el-col>
        </el-row>

        <el-table
          ref="multipleTable"
          v-tableHe
          size="mini"
          border
          indent="30"
          :data="tableData"
          style="width: 100%;margin-bottom: 20px;"
          row-key="myId"
          @select-all="selectAll"
          @selection-change="handleSelectionChange"
          @select="rowSelect"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            class-name="firstColumn"
            prop="value_code"
            :label="$t('cip.dc.dataDictionary.field.FieldCode')"
          >
            <template slot-scope="scope" style="display: flex;align-items: center">
              <el-input style="width: 400px" type="text" size="mini" v-model="scope.row.value_code"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="value_name"
            :label="$t('cip.dc.dataDictionary.field.DictionaryName')"
            width="300"
          >
            <template slot-scope="scope">
              <el-input type="text" size="mini" v-model="scope.row.value_name"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            v-if="form.dict_type=='tree'"
            align="center"
            prop="name"
            :label="$t('dataDictionary.label.operate')"
            width="120"
          >
            <template slot-scope="scope">
            <i @click="addTableData(scope.row)" class="el-icon-circle-plus-outline" style="font-size:30px;margin-right: 20px;cursor: pointer"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
  </div>
</template>

<script>
import {saveDict, getDiceDetaill} from "@/api/dataAcquisition/DataAssets";

export default {
  name: "dataCoonfigAdd",
  data(){
    return {
      tableID:0,
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        dict_code: [
          { required: true, message: this.$t('cip.dc.dataDictionary.field.FieldCode'), trigger: 'blur' }
        ],
        dict_name: [
          { required: true, message: this.$t('cip.dc.dataDictionary.field.DictionaryName'), trigger: 'blur' }
        ],
        dict_type: [
          { required: true, message: this.$t('cip.dc.dataDictionary.field.DictionaryType'), trigger: 'change' }
        ],
      },
      form: {
        dict_id:"",
        dict_code:"",
        dict_name:"",
        dict_type:"list"
      },
      formLabelWidth: '120px',
      multipleSelection:[],
      tableData:[],
      oldTableData:[],
      oldForm:{}
    }
  },
  created() {
    this.getDetailDataSource();
    if(this.$route.query.id){
      this.$route.meta.title = '数据字典编辑'
    }else{
      this.$route.meta.title = '数据字典新增'
    }
  },
  methods:{
    getDetailDataSource(){
      if(this.$route.query.type!=='edit') return
      getDiceDetaill({dict_id:this.$route.query.id}).then(res=>{
        let data =res.data.data;
        this.form =  data.mainForm;
        this.oldForm = JSON.parse(JSON.stringify(data.mainForm));
        this.setId(data.lineForm)
        this.tableData = data.lineForm;
        this.oldTableData =  JSON.parse(JSON.stringify(data.lineForm));
        console.log(this.tableData,'tableData')

        let arr = this.flatten(this.tableData);
        let arr2 = [];
        arr.forEach((element) => {
          arr2.push(element.myId);
        });
        let aa = Math.max.apply(null, arr2);
        this.tableID = aa;


      })
    },
    changeFn(val){
      console.log(val)
      console.log(this.oldForm.dict_type);
      if(val === this.oldForm.dict_type){
        this.tableData = this.oldTableData;
      }else{
        this.tableData = [];
      }
    },
    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },
   
    saveFn(type){
      let obj = this.form;
      this.$set(obj,'lineForm',this.tableData)
      obj.lineForm = this.tableData;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.tableData.length>0){
            var aa = JSON.parse(JSON.stringify(this.tableData));
          var alltree = this.flatten(aa);

          for (let i = 0;  i<alltree.length; i++) {
               
                if(!alltree[i].value_name||!alltree[i].value_code){
                  this.$message.error(this.$t('cip.dc.dataDictionary.msg.msg3'))
                     return
                }
              }


          
          }
          
           
          if(obj.dict_id){
            saveDict(obj).then(()=>{
              this.$message.success(this.$t('DataDirectory.msg.msg3'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }else{
            saveDict(obj).then(()=>{
              this.$message.success(this.$t('DataDirectory.msg.msg3'));
              if(type=='back'){
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleSelectionChange(val) {
      this.multipleSelection = [];
      this.setArrFn(val)
      console.log(this.multipleSelection)
    },
    tabelAdd(){
      this.tableID++;
      let obj = {
        dict_id:this.form.dict_id,
        value_code: '',
        value_id:'',
        value_pid:'',
        value_name: '',
        children:[],
        myId:this.tableID,
      }
      this.tableData.push(obj)
    },
    addTableData(item){
      this.tableID++;
      console.log(item,"asdasdasdasdd");
      if(!item.hasOwnProperty('children')){
        item.children = [];
      }
      let obj = {
        dict_id:this.form.dict_id,
        value_code: '',
        value_id:'',
        value_pid:'',
        value_name: '',
        children:[],
        myId:this.tableID,
      }
      if(item){
        this.$set(obj,'parentId',item.myId)
        this.setId(this.tableData,item.myId);
        item.children.push(obj);

      }else{
        this.tableData.push(obj)
        this.setId(this.tableData);
      }
    },
    setId(arr,parentId){
      arr.forEach((item,index)=>{
        if(parentId){
          item.myId = index+1+''+parentId;
        }else{
          item.myId = index+1;
        }
        if(item.children){
          this.setId(item.children,item.myId)
        }
      })
    },
    delTableData(index){
      // let temp = this.tableData.concat(this.multipleSelection).filter(item => !this.multipleSelection.includes(item.myId))
      // this.tableData = temp;
      if(this.multipleSelection.length == 0){
        return
      }



      let arr =this.multipleSelection

      console.log(this.multipleSelection,"sssssssssssssssssss",this.tableData);
      if(this.form.dict_type=="list"){
        this.tableData = this.tableData.filter(item => !arr.includes(item.myId));
      }else{
        // this.tableData = this.tableData.filter(item => !arr.includes(item.myId));
        arr.forEach(element => {
          console.log(element,'sadasdasdasdasdasd');
          this.removeNodeInTree(this.tableData,element)
        });
        
      }
      
      
    },


    removeNodeInTree(treeList, id) {
      // 通过id从数组（树结构）中移除元素
      if (!treeList || !treeList.length) {
        return;
      }
      for (let i = 0; i < treeList.length; i++) {
        if (treeList[i].myId === id) {
          treeList.splice(i, 1);
          break;
        }
        this.removeNodeInTree(treeList[i].children, id);
      }
    },

    removeTreeListItem(treeList, ids) { // 根据id属性从数组（树结构）中移除元素
      ids.forEach((id)=>{
        if (!treeList || !treeList.length) {
          return
        }
        for (let i = 0; i < treeList.length; i++) {
          if (treeList[i].id === id) {
            treeList.splice(i, 1);
            break;
          }
          this.removeTreeListItem(treeList[i].children, id)
        }
      })
    },
    setChildren(children, type) {
      // 编辑多个子层级
      children.map((j) => {
        this.toggleSelection(j, type);
        if (j.children) {
          this.setChildren(j.children, type);
        }
      });
    },
    // 选中父节点时，子节点一起选中取消
    rowSelect(selection, row) {
      const hasSelect = selection.some((el) => {
        return row.myId === el.myId;
      });
      if (hasSelect) {
        if (row.children) {
          // 解决子组件没有被勾选到
          this.setChildren(row.children, true);
        }
      } else {
        if (row.children) {
          this.setChildren(row.children, false);
        }
      }
    },
    toggleSelection(row, select) {
      if (row) {
        this.$nextTick(() => {
          this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection( row,select);
        });
      }
    },
    // 选择全部
    selectAll(selection) {
      // tabledata第一层只要有在selection里面就是全选
      const isSelect = selection.some((el) => {
        const tableDataIds = this.tableData.map((j) => j.myId);
        return tableDataIds.includes(el.myId);
      });
      // tableDate第一层只要有不在selection里面就是全不选
      const isCancel = !this.tableData.every((el) => {
        const selectIds = selection.map((j) => j.myId);
        return selectIds.includes(el.myId);
      });
      if (isSelect) {
        selection.map((el) => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, true);
          }
        });
      }
      if (isCancel) {
        this.tableData.map((el) => {
          if (el.children) {
            // 解决子组件没有被勾选到
            this.setChildren(el.children, false);
          }
        });
      }
    },
    setArrFn(arr){
      arr.forEach((item)=>{
        this.multipleSelection.push(item.myId);
        if(item.hasOwnProperty('children') && item.children.length>0){
          this.setArrFn(item.children)
        }
      })
    },

     //扁平化 树
     flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },
  },
}

</script>

<style >
  .firstColumn{
    display: flex;
  }
  .firstColumn .cell{
    display: flex;
    align-items: center;
  }
</style>
